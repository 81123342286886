import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
import { userApi } from '../../apis';
import { HTTP_STATUS } from "../../constants";
import { toast } from "react-toastify";

const UserProfile = () => {

  //meta title
  document.title = "Profile | Sprezzy - React Admin & Dashboard Template";
  const [me, setMe] = useState(null);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: me ? me.firstName : '',
      lastName: me ? me.lastName : '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your First Name"),
      lastName: Yup.string().required("Please Enter Your Last Name"),
    }),
    onSubmit: async (values) => {
      try {
        const { data, status } = await userApi.patchUserMe(values);
        if(status === 200) {
          setMe(data);
          toast.success('Update user name successfully');
        } else {
          toast.error('Update user name failed');
        }
      } catch (error) {
        toast.error('Update user name failed');
      }
    }
  });

  useEffect(() => {
    async function fetchMe() {
      const { data, status } = await userApi.getUserMe();
      if (status === HTTP_STATUS.OK) {
        setMe(data);
      }
    }
    fetchMe();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Sprezzy" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{me && `${me?.firstName} ${me?.lastName}`}</h5>
                        <p className="mb-0">Id no: #{me?.id}</p>
                        <p className="mb-1">{me && me?.email}</p>
                        <p className="mb-1">{me && me?.phoneNumber}</p>
                        <p className="mb-1">{me && me?.address1}</p>
                        <p className="mb-1">{me && me?.address2}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4" style={{ textAlign: "left" }}>Change User Name</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">First Name</Label>
                  <Input
                    name="firstName"
                    className="form-control"
                    placeholder="Enter First Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstName || ""}
                    invalid={
                      validation.touched.firstName && validation.errors.firstName ? true : false
                    }
                  />
                  {validation.touched.firstName && validation.errors.firstName ? (
                    <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                  ) : null}
                </div>
                <div className="form-group mt-2">
                  <Label className="form-label">Last Name</Label>
                  <Input
                    name="lastName"
                    className="form-control"
                    placeholder="Enter Last Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastName || ""}
                    invalid={
                      validation.touched.lastName && validation.errors.lastName ? true : false
                    }
                  />
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                  ) : null}
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
