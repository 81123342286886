import React, { useState, useEffect } from "react";
import { HTTP_STATUS } from "../../../../constants";
import { fabricApi } from "../../../../apis";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import { convertImagePath } from "../../../../helpers/utils";
import Dropzone from "react-dropzone";
import * as yup from "yup";
import { useFormik } from "formik";
import { getData } from '../../../../helpers/localStorage';
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
const AddFabric = () => {
  const token = getData("authUser");
  //meta title
  document.title = "Add Fabric | Sprezzy - React Admin & Dashboard Template";
  const location = useLocation();
  const state = location.state;

  const [selectedFiles, setselectedFiles] = useState([]);
  const [imageDefault, setImageDefault] = useState([]);
  const [cateList, setCateList] = useState([]);
  const [cateLocation, setCateLocation] = useState([]);
  
  useEffect(() => {
    async function getFabricType(id) {
      try {
        const { data, status } = await fabricApi.getFabricFabricType(id);
        if (status === HTTP_STATUS.OK) {
          const values = data?.
            filter(item => item?.value !== 'all_fabrics')?.
            map(item => {
              return {
                value: item.id,
                label: item.name
              }
            });
          formik.setFieldValue('category', values);
          setCateLocation(values);
        }
      } catch (error) {
        console.log('get attribute error: ', error);
      }
    };

    if(state?.item?.id) {
      getFabricType(state?.item?.id);
      setImageDefault([state?.item?.image]);
    }
    
  }, [state]);

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  
  const handleSetValuesToNull = () => {
    formik.setValues({
      productName: ' ',
      price: 0,
      category: '',
      productDesc: ' ',
      productValue: ' '
    });
  };
  
  //Basic Information
  const formik = useFormik({
    initialValues: {
      productName: state?.item?.name || '',
      price: state?.item?.price || 0,
      category: '',
      productDesc: state?.item?.description || '',
      productValue: state?.item?.value || ''
    },
    validationSchema: yup.object().shape({
      productName: yup.string().required('Please Enter Fabric Name'),
      price: yup.number().required('Please Enter Fabric Price'),
      productValue: yup.string().required('Please Enter Fabric Value'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if(state?.item) {
        try {
          let image = null;
          if(imageDefault?.length > 0) {
            image = imageDefault[0];
          } else if(selectedFiles?.length > 0) {
            image = selectedFiles[0];
          }
          const formData = new FormData();
          formData.append('imageFile', image);
          formData.append('name', values.productName);
          formData.append('price', values.price);
          formData.append('description', values.productDesc);
          formData.append('value', values.productValue);

          await axios.put(`${process.env.REACT_APP_BASE_URL}/fabric/${state?.item?.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`, // Add the token here
            },
          });
          if(values.category?.length > 0) {
            const deletePromises = cateLocation?.map(async (item) => {
              try {
                const { status } = await fabricApi.deleteFabricTypeFabricsFabricId(state?.item?.id, item?.value);
                return status === HTTP_STATUS.OK;
              } catch (error) {
                console.log('error create product: ', error);
              }
            });

            try {
              const results = await Promise.all(deletePromises);            
              const allDeletedSuccessfully = results.every(result => result === true);

              if (allDeletedSuccessfully) {
                const updatePromises = values.category.map(async (item) => {
                  const {status} = await fabricApi.postFabricTypeFabricsFabricId(
                    state?.item?.id, 
                    JSON.stringify({ 
                      fabricTypeId: item?.value
                    })
                  );
                  return status;
                });
                const results = await Promise.all(updatePromises);
                const allUpdateSuccessfully = results.every(result => result === 201);
                if(allUpdateSuccessfully) {
                  toast.success("Update fabric successfully!");
                }
              }
            } catch (error) {
              toast.error("Update fabric failed!");
            }
          }
          handleSetValuesToNull();
          setselectedFiles([]);
          setCateLocation([]);
        } catch (error) {
          toast.error("Update fabric failed!");
        }
      } else {
        try {
          if(selectedFiles?.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
              formData.append('imageFile', selectedFiles[0]);
            };
            formData.append('name', values.productName);
            formData.append('price', values.price);
            formData.append('description', values.productDesc);
            formData.append('value', values.productValue)
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/fabric`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`, // Add the token here
              },
            });
            if(response.status === HTTP_STATUS.CREATED) {
              if(values.category?.length > 0) {
                values.category.forEach(async (item) => {
                  try {
                    await fabricApi.postFabricTypeFabricsFabricId(response?.data?.id, 
                      JSON.stringify({ 
                        fabricTypeId: item?.value
                    })); 
                  } catch (error) {
                    console.log('error create product: ', error);
                  }
                })
              };
              toast.success("Add fabric successfully!");
              handleSetValuesToNull();
              setselectedFiles([]);
            }
          }
        } catch (error) {
          toast.error("Add fabric failed!");
        }
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    async function fetchCates() {
      try {
        const { data, status } = await fabricApi.getFabricType();        
        if (status === HTTP_STATUS.OK) {        
          const items = data?.filter(item => item.value !== 'all_fabrics')?.map(item => {
            return {
              value: item.id,
              label: item.name
            }
          })
          setCateList(items);
        }
      } catch (error) {
        console.log('get attribute error: ', error);
      }
    }
    fetchCates();
  }, []);

  const handleCategoryChange = (selectedOption) => {
    formik.setFieldValue('category', selectedOption);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Custom Suit-" breadcrumbItem={
            state && state?.item ? 'Edit Fabric' : 'Add Fabric'
          } />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <p className="card-title-desc mb-4">
                    Fill all information below
                  </p>

                  <Form onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      return false;
                    }} autoComplete="off">
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productName">Fabric's Name</Label>
                          <Input
                            id="productName"
                            name="productName"
                            type="text"
                            placeholder="Fabric's Name"
                            value={formik.values.productName}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.productName && formik.errors.productName ? true : false
                            }
                          />
                          {formik.errors.productName && formik.touched.productName ? (
                            <FormFeedback type="invalid">{formik.errors.productName}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productName">Fabric's value</Label>
                          <Input
                            id="productValue"
                            name="productValue"
                            type="text"
                            placeholder="Fabric's value"
                            value={formik.values.productValue}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.productValue && formik.errors.productValue ? true : false
                            }
                          />
                          {formik.errors.productValue && formik.touched.productValue ? (
                            <FormFeedback type="invalid">{formik.errors.productValue}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="price">Price</Label>
                          <Input
                            id="price"
                            name="price"
                            type="number"
                            placeholder="Price"
                            value={formik.values.price}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.price && formik.errors.price ? true : false
                            }
                          />
                          {formik.errors.price && formik.touched.price ? (
                            <FormFeedback type="invalid">{formik.errors.price}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="mb-3">
                          <Label className="control-label">Fabric type</Label>
                          <Select
                            isMulti
                            name="category" 
                            options={cateList} 
                            className="select2"
                            onChange={handleCategoryChange} 
                            defaultValue={formik.values.category}
                            value={formik.values.category}
                          />
                          {formik.errors.category && formik.touched.category ? (
                            <span className="text-danger">{formik.errors.category}</span>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="productDesc">
                            Fabric's Description
                          </Label>
                          <Input
                            tag="textarea"
                            className="mb-3"
                            id="productDesc"
                            name="productDesc"
                            rows={5}
                            placeholder="Fabric's Description"
                            value={formik.values.productDesc}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.productDesc && formik.errors.productDesc ? true : false
                            }
                          />
                          {formik.errors.productDesc && formik.touched.productDesc ? (
                            <FormFeedback type="invalid">{formik.errors.productDesc}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary"> Save Changes  </Button>
                      <Button type="button" color="secondary" onClick={() => formik.resetForm()}> Cancel</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Fabric Images</CardTitle>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <ul className="list-unstyled mb-0" id="file-previews">
                      {((imageDefault?.length > 0 ? imageDefault : selectedFiles) || [])?.map((file, index) => {                        
                        return (
                          <li className="mt-2 dz-image-preview" key=''>
                            <div className="border rounded">
                              <div className="d-flex flex-wrap gap-2 p-2">
                                <div className="flex-shrink-0 me-3">
                                  <div className="avatar-sm bg-light rounded p-2">
                                    <img data-dz-thumbnail="" className="img-fluid rounded d-block"
                                     src={imageDefault?.length > 0 ? convertImagePath(file) : file.preview}
                                     alt={imageDefault?.length > 0 ? '' : file.name} />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div className="pt-1">
                                    <h5 className="fs-md mb-1" data-dz-name>{file.path}</h5>
                                    <strong className="error text-danger" data-dz-errormessage></strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                    <div style={{
                      width: '100%',
                      paddingTop: '20px',
                      paddingRight: '10px',
                    }}>
                      <Button variant="danger" size="sm"
                        onClick={() => {
                          setselectedFiles([]);
                          setImageDefault([]);
                        }}>
                        Delete</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddFabric;
