import React, { useState } from "react";
import { HTTP_STATUS } from "../../constants";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { convertImagePath } from "../../helpers/utils";
import Dropzone from "react-dropzone";
import * as yup from "yup";
import { useFormik } from "formik";
import { getData } from '../../helpers/localStorage';
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
const BlogCreateNew = () => {
  const token = getData("authUser");
  //meta title
  document.title = "Add News | Sprezzy - React Admin & Dashboard Template";
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state;

  const [selectedFiles, setselectedFiles] = useState([]);
  const [imageDefault, setImageDefault] = useState(state?.item ? [state?.item?.thumbnail] : []);

  const [editorData, setEditorData] = useState(state?.item?.content || "");

  const handleEditorChange = (event, editor) => {
    setEditorError(false);
    const data = editor.getData();
    setEditorData(data);
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  
  const handleSetValuesToNull = () => {
    formik.setValues({
      title: '',
      summary: '',
      slug: '',
    });
  };
  
  //Basic Information
  const formik = useFormik({
    initialValues: {
      title: state?.item?.title || '',
      summary: state?.item?.summary || '',
      slug: state?.item?.slug || '',
    },
    validationSchema: yup.object().shape({
      title: yup.string().required('Please Enter News Title'),
      slug: yup.string().required('Please Enter News Slug'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if(state?.item) {
        try {
          let image = null;
          if(imageDefault?.length > 0) {
            image = imageDefault[0];
          } else if(selectedFiles?.length > 0) {
            image = selectedFiles[0];
          }
          const formData = new FormData();
          formData.append('thumbnail', image);
          formData.append('title', values.title);
          formData.append('summary', values.summary);
          formData.append('slug', values.slug);
          formData.append('content', editorData);

          const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/articles/${state?.item?.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`, // Add the token here
            },
          });
          if(response.status === HTTP_STATUS.OK) {
            toast.success('Update news successfully');
            handleSetValuesToNull();
            setselectedFiles([]);
            setImageDefault([]);
            navigate('/news-list');
          } else {
            toast.error('Update news failed');
          }
        } catch (error) {
          toast.error('Update news failed');
        }
      } else {
        try {
          if(selectedFiles?.length > 0) {
            const formData = new FormData();
            formData.append('thumbnail', selectedFiles[0]);
            formData.append('title', values.title);
            formData.append('summary', values.summary);
            formData.append('slug', values.slug);
            formData.append('content', editorData);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/articles`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`, // Add the token here
              },
            });
            if(response.status === HTTP_STATUS.CREATED) {
              toast.success('Create news successfully');
              handleSetValuesToNull();
              setselectedFiles([]);
              setImageDefault([]);
              navigate('/news-list');
            } else {
              toast.error('Create news failed');
            }
          }
        } catch (error) {
          toast.error('Create news failed');
        }
      }
      setSubmitting(false);
    },
  });

  const [editorError, setEditorError] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (editorData === "") {
      // Display error message
      setEditorError(true);
    } 
    formik.handleSubmit();
  };

  function uploadAdapter(loader) {
      return {
          upload: () => {
              return new Promise((resolve, reject) => {
                  const body = new FormData();
                  loader.file.then((file) => {
                      body.append('image', file);
                      axios.post(`${process.env.REACT_APP_BASE_URL}/articles/upload`, body, {
                          headers: {
                              "Content-Type": "multipart/form-data",
                              'Authorization': `Bearer ${token}`,
                          },
                      })
                      .then((response) => {
                          console.log('my res', response);
                          if(response?.status === HTTP_STATUS.CREATED) {
                            resolve({ default: `${convertImagePath(response.data.imagePath)}` })
                          }
                      })
                      .catch((error) => {
                          console.log(error);
                      });
                  })
              })
          }
      }
  }

  function uploadPlugin(editor) {
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
          return new uploadAdapter(loader)
      }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs breadcrumbItem={
            state && state?.item ? 'Edit News' : 'Add News'
          } />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <p className="card-title-desc mb-4">
                    Fill all information below
                  </p>

                  <Form onSubmit={handleSubmit} autoComplete="off">
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="title">Title</Label>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            placeholder="Title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.title && formik.errors.title ? true : false
                            }
                          />
                          {formik.errors.title && formik.touched.title ? (
                            <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="summary">Summary</Label>
                          <Input
                            id="summary"
                            name="summary"
                            type="text"
                            placeholder="Summary"
                            value={formik.values.summary}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.summary && formik.errors.summary ? true : false
                            }
                          />
                          {formik.errors.summary && formik.touched.summary ? (
                            <FormFeedback type="invalid">{formik.errors.summary}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="slug">Slug</Label>
                          <Input
                            id="slug"
                            name="slug"
                            type="text"
                            placeholder="Slug"
                            value={formik.values.slug}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.slug && formik.errors.slug ? true : false
                            }
                          />
                          {formik.errors.slug && formik.touched.slug ? (
                            <FormFeedback type="invalid">{formik.errors.slug}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm="12">
                            <CKEditor
                              editor={ClassicEditor}
                              data={editorData}
                              onChange={handleEditorChange}
                              config={{
                                mediaEmbed: {
                                  previewsInData: true, // Cho phép nhúng video
                                },
                                extraPlugins: [uploadPlugin],
                              }}
                            />
                            {editorError ? (
                                <div className="ckeditor-invalid">
                                    <FormFeedback type="invalid">Please Enter News Content</FormFeedback>
                                </div>
                            ) : null}
                            {/* <div className="mt-3">
                              <h5>Preview Content:</h5>
                              <div
                                dangerouslySetInnerHTML={{ __html: editorData }}
                                style={{ border: "1px solid #ccc", padding: "10px" }}
                              ></div>
                            </div> */}
                        </Col>
                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary"> Save Changes  </Button>
                      <Button type="button" color="secondary" onClick={() => formik.resetForm()}> Cancel</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Thumbnail</CardTitle>
                  <Form>
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <ul className="list-unstyled mb-0" id="file-previews">
                      {((imageDefault?.length > 0 ? imageDefault : selectedFiles) || [])?.map((file, index) => {                        
                        return (
                          <li className="mt-2 dz-image-preview" key=''>
                            <div className="border rounded">
                              <div className="d-flex flex-wrap gap-2 p-2">
                                <div className="flex-shrink-0 me-3">
                                  <div className="avatar-sm bg-light rounded p-2">
                                    <img data-dz-thumbnail="" className="img-fluid rounded d-block"
                                     src={imageDefault?.length > 0 ? convertImagePath(file) : file.preview}
                                     alt={imageDefault?.length > 0 ? '' : file.name} />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div className="pt-1">
                                    <h5 className="fs-md mb-1" data-dz-name>{file.path}</h5>
                                    <strong className="error text-danger" data-dz-errormessage></strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                    <div style={{
                      width: '100%',
                      paddingTop: '20px',
                      paddingRight: '10px',
                    }}>
                      <Button variant="danger" size="sm"
                        onClick={() => {
                          setselectedFiles([]);
                          setImageDefault([]);
                        }}>
                        Delete</Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default BlogCreateNew;
