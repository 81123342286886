import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { HTTP_STATUS } from "../../constants";
import { homepageConfigApi } from "../../apis";
import "flatpickr/dist/themes/material_blue.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import { convertImagePath } from "helpers/utils";
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { getData } from '../../helpers/localStorage';
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";

const HomepageConfig = () => {
  document.title = "Homepage Config | Sprezzy - Homepage Background Settings";
  const [configs, setConfigs] = useState(Array(6).fill(null));
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const token = getData("authUser");

  useEffect(() => {
    getConfigs();
  }, []);

  const getConfigs = async () => {
    setLoading(true);
    try {
      const {data, status} = await homepageConfigApi.getHomepageConfigs();
      if (status === HTTP_STATUS.OK) {
        // Initialize array with 6 null slots
        const newConfigs = Array(6).fill(null);
        
        // Sort data by order field
        const sortedData = [...data].sort((a, b) => a.order - b.order);
        
        // Map existing configs to their positions
        sortedData.forEach((config) => {
          const order = config.order;
          if (order >= 0 && order < 6) {
            newConfigs[order] = {
              ...config,
              // Take only the first image URL if it exists
              imageUrls: config.imageUrls?.length > 0 ? [config.imageUrls[0]] : []
            };
          }
        });
        
        setConfigs(newConfigs);
      }
    } catch (error) {
      console.error('Error fetching configs:', error);
    }
    setLoading(false);
  };

  const handleAcceptedFiles = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      });
      setSelectedFile(file);
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.info("Please select an image!");
      return;
    }

    try {
      const formData = new FormData();
      formData.append('imageFiles', selectedFile);
      formData.append('title', `Background ${currentIndex + 1}`);
      formData.append('description', `Background image for slot ${currentIndex + 1}`);
      formData.append('slug', `background-${currentIndex + 1}`);
      formData.append('order', currentIndex); // Add order field
      
      const url = configs[currentIndex]
        ? `${process.env.REACT_APP_BASE_URL}/homepage-settings/${configs[currentIndex].id}`
        : `${process.env.REACT_APP_BASE_URL}/homepage-settings`;
        
      const response = await axios({
        method: configs[currentIndex] ? 'PUT' : 'POST',
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      if(response.status === HTTP_STATUS.OK || response.status === HTTP_STATUS.CREATED) {
        toast.success(`Background image ${configs[currentIndex] ? 'updated' : 'added'} successfully!`);
        setModal(false);
        setSelectedFile(null);
        getConfigs(); // Refresh the images
      }
    } catch (error) {
      console.log('error saving homepage settings: ', error);
      toast.error(`Failed to ${configs[currentIndex] ? 'update' : 'add'} background image!`);
    }
  };

  const handleEditImage = (index) => {
    setCurrentIndex(index);
    setSelectedFile(null);
    setModal(true);
  };

  const ImageSlot = ({ config, index, isFullWidth }) => {
    return (
      <Card className={`mb-4 ${isFullWidth ? 'col-12' : 'col-6'}`}>
        <CardBody>
          <div style={{ 
            position: 'relative',
            height: isFullWidth ? '400px' : '300px',
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            overflow: 'hidden'
          }}>
            {config?.imageUrls?.[0] ? (
              <>
                <img 
                  src={convertImagePath(config.imageUrls[0])} 
                  alt={`Background ${index + 1}`}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover'
                  }}
                />
                <Button
                  color="primary"
                  className="position-absolute"
                  style={{ bottom: '20px', right: '20px' }}
                  onClick={() => handleEditImage(index)}
                >
                  Change Image
                </Button>
              </>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center h-100">
                <i className="bx bx-image-add display-4 text-muted mb-2" />
                <p className="text-muted mb-3">No image uploaded</p>
                <Button
                  color="primary"
                  onClick={() => handleEditImage(index)}
                >
                  Upload Image
                </Button>
              </div>
            )}
          </div>
          <div className="mt-3">
            <h5 className="mb-1">
              {isFullWidth ? 'Full Width Image' : 'Half Width Image'} {index + 1}
            </h5>
            <p className="text-muted mb-0">
              {config?.title || `Slot ${index + 1} - Click to upload image`}
            </p>
          </div>
        </CardBody>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Homepage Background Config" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <ImageSlot config={configs[0]} index={0} isFullWidth={true} />
              <ImageSlot config={configs[1]} index={1} isFullWidth={false} />
              <ImageSlot config={configs[2]} index={2} isFullWidth={false} />
              <ImageSlot config={configs[3]} index={3} isFullWidth={true} />
              <ImageSlot config={configs[4]} index={4} isFullWidth={false} />
              <ImageSlot config={configs[5]} index={5} isFullWidth={false} />
            </Row>
          )}
        </Container>
      </div>

      {/* Upload Modal */}
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(!modal);
          setSelectedFile(null);
        }}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={() => {
          setModal(!modal);
          setSelectedFile(null);
        }}>
          {configs[currentIndex] ? 'Change Image' : 'Upload New Image'}
        </ModalHeader>
        <ModalBody>
          <div className="mb-4">
            <Dropzone
              onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
              accept={{
                'image/*': ['.png', '.jpg', '.jpeg', '.gif']
              }}
              maxFiles={1}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone" style={{ 
                  minHeight: '300px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                  cursor: 'pointer',
                  backgroundColor: selectedFile ? 'transparent' : '#f8f9fa'
                }}>
                  {selectedFile ? (
                    <div style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                      <img
                        src={selectedFile.preview}
                        alt={selectedFile.name}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          padding: '20px'
                        }}
                      />
                      <div style={{
                        position: 'absolute',
                        bottom: '20px',
                        right: '20px',
                        display: 'flex',
                        gap: '10px'
                      }}>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedFile(null);
                          }}
                        >
                          Remove
                        </Button>
                      </div>
                      <div style={{
                        position: 'absolute',
                        bottom: '20px',
                        left: '20px',
                        background: 'rgba(255,255,255,0.9)',
                        padding: '5px 10px',
                        borderRadius: '4px'
                      }}>
                        <p className="text-muted mb-0" style={{ fontSize: '0.875rem' }}>
                          {selectedFile.name} ({selectedFile.formattedSize})
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="dz-message needsclick text-center"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>Drop image here or click to upload</h4>
                      <p className="text-muted">
                        <small>Supported formats: PNG, JPG, JPEG, GIF</small>
                      </p>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          </div>

          <div className="text-end mt-3">
            <Button color="light" onClick={() => {
              setModal(false);
              setSelectedFile(null);
            }}>
              Cancel
            </Button>
            <Button
              color="primary"
              className="ms-2"
              onClick={handleUpload}
              disabled={!selectedFile}
            >
              Upload
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <ToastContainer />
    </React.Fragment>
  );
};

export default HomepageConfig;
