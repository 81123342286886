import React, { useState } from "react"
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import { convertImagePath } from "helpers/utils";
import classnames from "classnames"
import { newsApi } from "apis";
import { format } from 'date-fns';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const BlogGrid = ({ news }) => {
  const [activeTab, toggleTab] = useState("1")
  const navigate = useNavigate();
  const toggle = tab => {
    if (activeTab !== tab) toggleTab(tab)
  }

  const handleDeleteProduct = async (id) => {
    try {
      const { status } = await newsApi.deleteArticles(id);
      if(status === 200) {
        toast.success('Delete news successfully!');
        navigate('/news-list')
      } else {
        toast.error('Delete news failed!')
      }
    } catch (error) {
      toast.error('Delete news failed!')
    }
  };

  return (
    <React.Fragment>
      <Col xl={9} lg={8}>
        <Card>
          <Nav tag="ul" className="nav-tabs nav-tabs-custom justify-content-center pt-2" role="tablist">
            <NavItem tag="li">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1")
                }}
              >
                All Post
              </NavLink>
            </NavItem>
            <NavItem tag="li">
              <NavLink
                to="#"
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2")
                }}
              >
                Archive
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="p-4" activeTab={activeTab}>
            <TabPane tabId="1">
              <div>
                <Row className="justify-content-center">
                  <Col xl={8}>
                    <div>
                      <div className="row align-items-center">
                        <div className="col-4">
                          <div>
                            <h5 className="mb-0">News Grid</h5>
                          </div>
                        </div>

                        <Col xs={8}>
                          <div className="float-end">
                            <Nav tag="ul" className="nav-pills justify-content-end">
                              <NavItem tag="li">
                                <NavLink
                                  className="disabled"
                                  to="#"
                                  tabIndex="-1"
                                >
                                  View :
                                </NavLink>
                              </NavItem>
                              <NavItem tag="li">
                                <Link className="nav-link" to="/news-list">
                                  <i className="mdi mdi-format-list-bulleted"></i>
                                </Link>
                              </NavItem>
                              <NavItem>
                                <Link
                                  to="/news-grid"
                                  className="nav-link active"
                                >
                                  <i className="mdi mdi-view-grid-outline"></i>
                                </Link>
                              </NavItem>
                            </Nav>
                          </div>
                        </Col>
                      </div>

                      <hr className="mb-4" />
                      <Row>
                        {
                          news?.map((blog, index) => (
                            <Col sn={6} key={index}>
                              <Card className="p-1 border shadow-none">
                                <div className="p-3">
                                  <h5>
                                    <Link to={`/news-details/${blog.slug}/${blog.id}`} className="text-dark">
                                      {blog?.title}
                                    </Link>
                                  </h5>
                                  <p className="text-muted mb-0">
                                    {blog?.modifiedAt && format(new Date(blog.modifiedAt), 'yyyy-MM-dd HH:mm:ss')}
                                  </p>
                                </div>
    
                                <div className="position-relative">
                                  <img
                                    src={convertImagePath(blog?.thumbnail)}
                                    alt=""
                                    className="img-thumbnail"
                                  />
                                </div>
    
                                <div className="p-3">
                                  <div 
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center'
                                    }}
                                  >
                                    <Link to={`/news-details/${blog.slug}/${blog.id}`} className="text-primary">
                                      Read more{" "}
                                      <i className="mdi mdi-arrow-right"></i>
                                    </Link>
                                    <div 
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: '10px',
                                        cursor: 'pointer'
                                      }}>
                                      <i className="fas fa-trash" onClick={
                                        () => {
                                          if (window.confirm("Do you really want to delete new?")) {
                                            handleDeleteProduct(blog.id)
                                          }
                                        }
                                      }></i>
                                      <i className="fas fa-edit" onClick={() => {
                                        navigate(`/news-create-new`, {
                                          state: { item: blog }
                                        })
                                      }}></i>
                                    </div>
                                  </div>
                                </div>
                              </Card>
                            </Col> 
                          ))
                        }
                      </Row>

                      <hr className="my-4" />
                    </div>
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane tabId="2">
              <div>
                <Row className="justify-content-center">
                  <Col xl={8}>
                    <h5>Archive</h5>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="ms-2">
                          <h4>2020</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">
                            03
                          </span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i>{" "}
                          Beautiful Day with Friends
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Drawing
                          a sketch
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Project
                          discussion with team
                        </Link>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="ms-2">
                          <h4>2019</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">
                            06
                          </span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Coffee
                          with Friends
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Neque
                          porro quisquam est
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Quis
                          autem vel eum iure
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Cras mi
                          eu turpis
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Drawing
                          a sketch
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Project
                          discussion with team
                        </Link>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="d-flex flex-wrap">
                        <div className="ms-2">
                          <h4>2018</h4>
                        </div>
                        <div className="ms-auto">
                          <span className="badge badge-soft-success badge-pill float-right ms-1 font-size-12">
                            03
                          </span>
                        </div>
                      </div>
                      <hr className="mt-2" />

                      <div className="list-group list-group-flush">
                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i>{" "}
                          Beautiful Day with Friends
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Drawing
                          a sketch
                        </Link>

                        <Link
                          to="/blog-details"
                          className="list-group-item text-muted"
                        >
                          <i className="mdi mdi-circle-medium ms-1"></i> Project
                          discussion with team
                        </Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </TabPane>
          </TabContent>
        </Card>
      </Col>
    </React.Fragment>
  )
}
export default BlogGrid
