import React, { useState, useEffect } from "react";
import { HTTP_STATUS } from "../../constants";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Row,
  } from "reactstrap";
import axios from "axios";
import Dropzone from "react-dropzone";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { convertImagePath } from "../../helpers/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import { getData } from '../../helpers/localStorage';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";

const AddHomepageConfig = () => {
    const token = getData("authUser");
    const location = useLocation();
    const navigate = useNavigate();
    const { slotIndex, config } = location.state || {};
    const isFullWidth = slotIndex === 0 || slotIndex === 3;
    
    //meta title
    document.title = "Edit Background Image | Sprezzy - Homepage Settings";
  
    const [selectedFile, setSelectedFile] = useState(null);
    
    function handleAcceptedFiles(files) {
      if (files && files.length > 0) {
        const file = files[0];
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        });
        setSelectedFile(file);
      }
    }
  
    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes"
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    
    const formik = useFormik({
      initialValues: {
        title: config?.title || '',
        description: config?.description || '',
        slug: config?.slug || `background-${slotIndex + 1}`,
      },
      validationSchema: yup.object().shape({
        title: yup.string().required('Please enter title!'),
        slug: yup.string().required('Please enter slug!'),
      }),
      onSubmit: async (values, { setSubmitting }) => {
        try {
          if (!selectedFile) {
            toast.info("Please select an image!");
            return;
          }

          const formData = new FormData();
          formData.append('imageFile', selectedFile);
          formData.append('title', values.title);
          formData.append('description', values.description);
          formData.append('slug', values.slug);
          formData.append('slotIndex', slotIndex);
          
          const url = config 
            ? `${process.env.REACT_APP_BASE_URL}/homepage-settings/${config.id}`
            : `${process.env.REACT_APP_BASE_URL}/homepage-settings`;
            
          const response = await axios({
            method: config ? 'PUT' : 'POST',
            url,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`,
            },
          });

          if(response.status === HTTP_STATUS.OK || response.status === HTTP_STATUS.CREATED) {
            toast.success(`Background image ${config ? 'updated' : 'added'} successfully!`);
            navigate('/homepage-config');
          }
        } catch (error) {
          toast.error(`Failed to ${config ? 'update' : 'add'} background image!`);
        }
        setSubmitting(false);
      },
    });
    
    useEffect(() => {
      if (config?.imageUrls?.[0]) {
        setSelectedFile({
          preview: convertImagePath(config.imageUrls[0]),
          name: config.imageUrls[0].split('/').pop(),
        });
      }
    }, [config]);

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs breadcrumbItem={`${config ? 'Edit' : 'Add'} Background Image - Slot ${slotIndex + 1}`} />
  
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {isFullWidth ? 'Full Width Image' : 'Half Width Image'} - Slot {slotIndex + 1}
                    </CardTitle>

                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }} autoComplete="off">
                      <Row>
                        <Col sm="6">
                          <div className="mb-3">
                            <Label htmlFor="title">Title</Label>
                            <Input
                              id="title"
                              name="title"
                              type="text"
                              placeholder="Title"
                              value={formik.values.title}
                              onChange={formik.handleChange}
                              invalid={
                                formik.touched.title && formik.errors.title ? true : false
                              }
                            />
                            {formik.errors.title && formik.touched.title ? (
                              <FormFeedback type="invalid">{formik.errors.title}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="mb-3">
                            <Label htmlFor="title">Slug</Label>
                            <Input
                              id="slug"
                              name="slug"
                              type="text"
                              placeholder="Slug"
                              value={formik.values.slug}
                              onChange={formik.handleChange}
                              invalid={
                                formik.touched.slug && formik.errors.slug ? true : false
                              }
                            />
                            {formik.errors.slug && formik.touched.slug ? (
                              <FormFeedback type="invalid">{formik.errors.slug}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <div className="mb-3">
                            <Label htmlFor="description">Description</Label>
                            <Input
                              id="description"
                              name="description"
                              type="textarea"
                              placeholder="Description"
                              value={formik.values.description}
                              onChange={formik.handleChange}
                              invalid={
                                formik.touched.description && formik.errors.description ? true : false
                              }
                            />
                            {formik.errors.description && formik.touched.description ? (
                              <FormFeedback type="invalid">{formik.errors.description}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col sm="12">
                          <div className="mb-3">
                            <Label>Background Image</Label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                              }}
                              accept={{
                                'image/*': ['.png', '.jpg', '.jpeg', '.gif']
                              }}
                              maxFiles={1}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="dz-message needsclick">
                                      <div className="mb-3">
                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h4>Drop image here or click to upload.</h4>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            
                            {selectedFile && (
                              <div className="mt-3">
                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          className="avatar-xxl rounded bg-light"
                                          alt={selectedFile.name}
                                          src={selectedFile.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {selectedFile.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{selectedFile.formattedSize}</strong>
                                        </p>
                                      </Col>
                                      <Col className="col-auto">
                                        <Button
                                          color="danger"
                                          size="sm"
                                          onClick={() => setSelectedFile(null)}
                                        >
                                          Delete
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2">
                        <Button type="submit" color="primary">
                          {config ? 'Update' : 'Save'} Changes
                        </Button>
                        <Button type="button" color="secondary" onClick={() => navigate('/homepage-config')}>
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
  
  export default AddHomepageConfig;